import { parameterExtraction, docWithSeo } from '@kaliber/sanity-routing/sanity'
import { toPlainText } from '@portabletext/react'
import { asRouteMap } from '@kaliber/routing'
import cookie from 'cookie'
import groq from 'groq'

import { snippets } from '/groqSnippets'
import { makeSlug } from '/machinery/makeSlug'
import { wordCount } from '/machinery/tracking/metadata'
import { cookieConsent } from '/machinery/tracking/cookieConsent'
import { mapJobForDataLayer } from '/machinery/tracking/pushToDataLayer'
import { unixTimeStampToFormattedDate, utcDateTimeToFormattedDate } from '/machinery/dayjs'
import { NOT_FOUND, FORBIDDEN } from '/machinery/statusCodes'
import { translateSingular } from '/i18n/translations'

const { extract, language, slug } = parameterExtraction

export const JOBS_PER_PAGE = 15

const routeData = getRouteData()

export const routeMap = asRouteMap(
  {
    root: '',
    api: {
      path: 'api',

      v1: {
        path: 'v1',

        url: {
          path: 'document/path',
          data: requestHandlers => requestHandlers.determineDocumentPath,
        },

        jobs: {
          path: 'jobs',
          data: requestHandlers => requestHandlers.getJobs,
        },

        job: {
          path: 'job',
          isOpen: {
            path: 'is-open',
            internal: {
              path: 'internal',
              data: requestHandlers => requestHandlers.isInternalJobOpen
            },
            external: {
              path: 'external',
              data: requestHandlers => requestHandlers.isJobOpen
            },
          }
        },

        referAFriend: {
          path: 'refer-a-friend',
          data: requestHandlers => requestHandlers.referAFriend
        },

        jobFilters: {
          path: 'job-filters',
          data: requestHandlers => requestHandlers.jobFilters,
        },

        externalJobs: {
          path: 'external_jobs',
          data: requestHandlers => requestHandlers.getExternalJobs,
        },

        techAndDataJobs: {
          path: 'tech-and-data',
          data: requestHandlers => requestHandlers.getTechAndDataJobs,
        },

        auth: {
          path: 'auth',
          login: {
            path: 'login',
            data: requestHandlers => requestHandlers.login
          },
          userinfo: {
            path: 'userinfo',
            data: requestHandlers => requestHandlers.userInfo
          },
          logout: {
            path: 'logout',
            data: requestHandlers => requestHandlers.logout
          },
          handleSamlCallback: {
            path: 'callback',
            data: requestHandlers => requestHandlers.samlCallback
          },
        },
      },

      sitemap: {
        path: 'sitemap',
        data: requestHandlers => requestHandlers.sitemap,
      },

      linkedIn: {
        path: 'linkedin',
        data: requestHandlers => requestHandlers.linkedInFeed,
      },

      indeed: {
        path: 'indeed',
        data: requestHandlers => requestHandlers.indeedFeed,
      },

      notFound: '*'
    },
    preview: 'preview',
    app: {
      path: ':language',
      data: routeData.app.data,

      home: {
        path: '',
        data: routeData.app.home.data,
      },
      privacy: {
        path: 'privacy',
        data: routeData.app.privacy.data,
      },
      about: {
        path: 'about',
        data: routeData.app.about.data,
      },
      faq: {
        path: 'faq',
        index: {
          path: '',
          data: routeData.app.faq.data,
        },
        detail: {
          path: ':slug',
          data: routeData.app.faq.detail.data,
        },
      },
      page: {
        path: ':slug',
        data: routeData.app.page.data,
      },
      internalPage: {
        path: 'internal/:slug',
        data: routeData.app.internal.data,
      },
      article: {
        path: 'article/:slug',
        data: routeData.app.article.data,
      },
      project: {
        path: 'project/:slug',
        data: routeData.app.project.data,
      },
      campaign: {
        path: 'campaign/:slug',
        data: routeData.app.campaign.data,
      },
      xxlCampaignPage: {
        path: 'job-area/technology/techniekxxl',
        data: routeData.app.xxlCampaignPage.data,
      },
      xxlCampaignSubpage: {
        path: 'job-area/technology/techniekxxl/:slug',
        data: routeData.app.xxlCampaignSubpage.data,
      },
      division: {
        path: 'division/:slug',
        data: routeData.app.division.data,
      },
      lifeAtKLM: {
        path: 'life-at-klm',
        data: routeData.app.lifeAtKLM.data,
      },
      subsidiary: {
        path: 'subsidiary/:slug',
        data: routeData.app.subsidiary.data,
      },
      jobArea: {
        path: 'job-area/:slug',
        data: routeData.app.jobArea.data,
      },
      jobs: {
        path: 'jobs',
        index: {
          path: '',
          data: routeData.app.jobs.data
        },

        detail: {
          path: ':jobTitle/:jobId',
          data: routeData.app.jobs.detail.data,
        },
        apply: {
          path: ':jobTitle/:jobId/apply',
          data: routeData.app.jobs.apply.data,
        },

        privateDetail: {
          path: 'private/external/:jobId',
          data: routeData.app.jobs.privateDetail.data,
        },
        privateApply: {
          path: 'private/external/:jobId/apply',
          data: routeData.app.jobs.privateDetail.data,
        },

        internalDetail: {
          path: 'internal/:jobTitle/:jobId',
          data: routeData.app.jobs.internalDetail.data,
        },
        internalApply: {
          path: 'internal/:jobTitle/:jobId/apply',
          data: routeData.app.jobs.internalApply.data,
        },
        internalRefer: {
          path: 'internal/:jobTitle/:jobId/refer',
          data: routeData.app.jobs.internalReferrer.data,
        },

        privateInternalDetail: {
          path: 'private/internal/:jobId',
          data: routeData.app.jobs.privateInternalDetail.data,
        },
        privateInternalApply: {
          path: 'private/internal/:jobId/apply',
          data: routeData.app.jobs.privateInternalApply.data,
        },
      },
      notFound: '*',
    },
    admin: 'admin',
  },
  { trailingSlash: true }
)

function getRouteData() {
  return {
    app: {
      data: {
        groq: ({ params: { language } }) => ({
          settings: snippets.settings(),
          menu: snippets.menuItems({ language }),
          footerMenu: snippets.footerMenuItems({ language }),
          excludedIpAddresses: snippets.excludedIps(),
        }),
        fetch: {
          cookieConsent: requestHandlers => requestHandlers.getCookieConsent,
          userInfo: requestHandlers => requestHandlers.getUserInfo
        },
        derived: ({ data, params, query, headers, ip }) => ({
          ...isExcludedFromTracking({ excludedIps: data.excludedIpAddresses, query, headers, ip }),
          excludedIpAddresses: [], //Do not leak all saved ip adresses!
          dataLayer: {
            ...cookieConsent(data?.cookieConsent ?? null),
            metadata: {
              content: {
                language: params.language,
                ...(data.doc?._id && { id: data.doc?._id }),
              }
            }
          }
        }),
      },

      home: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: snippets.singletonDocument('home', snippets.projections.home, { language }),
          }),
          derived: ({ data, derived, route }) => deriveGenericDocumentInformation('homepage', { data, derived, route }),
          extractParams: { home: extract(language) }
        }
      },

      privacy: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: snippets.singletonDocument('privacy', snippets.projections.privacy, { language }),
          }),
          derived: ({ data, derived, route }) => deriveGenericDocumentInformation('privacy', { data, derived, route }),
          extractParams: { privacy: extract(language) }
        }
      },

      about: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: snippets.singletonDocument('about', snippets.projections.about, { language }),
          }),
          derived: ({ data, derived, route }) => deriveGenericDocumentInformation('about', { data, derived, route }),
          extractParams: { about: extract(language) }
        }
      },

      lifeAtKLM: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: snippets.singletonDocument('lifeAtKLM', snippets.projections.lifeAtKLM, { language }),
          }),
          derived: ({ data, derived, route }) => deriveGenericDocumentInformation('lifeAtKLM', { data, derived, route }),
          extractParams: { lifeAtKLM: extract(language) }
        }
      },

      faq: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: snippets.singletonDocument('faqOverview', snippets.projections.faqOverview, { language }),
            faqs: snippets.documents('faq', snippets.projections.faq, { language }),
          }),
          derived: ({ data, derived, route }) => deriveGenericDocumentInformation('faqOverview', { data, derived, route }),
          extractParams: { faqOverview: extract(language) }
        },

        detail: {
          data: {
            groq: ({ params: { language, slug } }) => ({
              doc: snippets.document('faq', snippets.projections.faq, { language, slug }),
            }),
            derived: ({ data, derived, route }) => deriveGenericDocumentInformation('faq', { data, derived, route }),
            extractParams: { faq: extract(language, slug) }
          }
        }
      },

      page: {
        data: {
          groq: ({ params: { language, slug } }) => ({
            doc: snippets.document('page', snippets.projections.page, { language, slug }),
          }),
          fetch: {
            normalizedContent: requestHandlers => requestHandlers.normalizeContent,
          },
          derived: ({ data, derived, route }) => deriveGenericDocumentInformation('content', { data: withNormalizedContent(data), derived, route }),
          extractParams: { page: extract(language, slug) },
        },
      },

      internal: {
        data: {
          groq: ({ params: { language, slug } }) => ({
            doc: snippets.document('internalPage', snippets.projections.internal, { language, slug }),
          }),
          derived: ({ data, derived, route }) => deriveInternalDocumentInformation('internalContent', { data, derived, route }, { doc: { title: translateSingular(data.language, 'not-authorized') } }),
          extractParams: { internalPage: extract(language, slug) },
        },
      },

      article: {
        data: {
          groq: ({ params: { language, slug } }) => ({
            doc: snippets.document('article', snippets.projections.article, { language, slug }),
          }),
          derived: ({ data, derived, route }) => deriveGenericDocumentInformation('article', { data, derived, route }),
          extractParams: { article: extract(language, slug) },
        },
      },

      project: {
        data: {
          groq: ({ params: { language, slug } }) => ({
            doc: snippets.document('project', snippets.projections.project, { language, slug }),
          }),
          fetch: {
            normalizedContent: requestHandlers => requestHandlers.normalizeContent,
          },
          derived: ({ data, derived, route }) => deriveGenericDocumentInformation('project', { data: withNormalizedContent(data), derived, route }),
          extractParams: { project: extract(language, slug) },
        },
      },

      campaign: {
        data: {
          groq: ({ params: { language, slug } }) => ({
            doc: snippets.document('campaign', snippets.projections.campaign, { language, slug }),
          }),
          fetch: {
            jobs: requestHandlers => requestHandlers.jobsByJobAreas,
            normalizedContent: requestHandlers => requestHandlers.normalizeContent,
          },
          derived: ({ data, derived, route }) => deriveGenericDocumentInformation('campaign', { data: withNormalizedContent(data), derived, route }),
          extractParams: { campaign: extract(language, slug) },
        },
      },

      xxlCampaignPage: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: snippets.singletonDocument('xxlCampaignPage', snippets.projections.xxlCampaignPage, { language }),
          }),
          fetch: {
            normalizedContent: requestHandlers => requestHandlers.normalizeContent,
          },
          derived: ({ data, derived, route }) => deriveGenericDocumentInformation('xxlCampaignPage', { data: withNormalizedContent(data), derived, route }),
          extractParams: { xxlCampaignPage: extract(language) }
        },
      },

      xxlCampaignSubpage: {
        data: {
          groq: ({ params: { language, slug } }) => ({
            doc: snippets.document('xxlCampaignSubpage', snippets.projections.xxlCampaignSubpage, { language, slug }),
          }),
          fetch: {
            normalizedContent: requestHandlers => requestHandlers.normalizeContent,
          },
          derived: ({ data, derived, route }) => deriveGenericDocumentInformation('xxlCampaignSubpage', { data: withNormalizedContent(data), derived, route }),
          extractParams: { xxlCampaignSubpage: extract(language, slug) },
        },
      },

      division: {
        data: {
          groq: ({ params: { language, slug } }) => ({
            doc: snippets.document('division', snippets.projections.division, { language, slug }),
          }),
          fetch: {
            normalizedContent: requestHandlers => requestHandlers.normalizeContent,
          },
          derived: ({ data, derived, route }) => deriveGenericDocumentInformation('division', { data: withNormalizedContent(data), derived, route }),
          extractParams: { division: extract(language, slug) },
        },
      },

      subsidiary: {
        data: {
          groq: ({ params: { language, slug } }) => ({
            doc: snippets.document('subsidiary', snippets.projections.subsidiary, { language, slug }),
          }),
          fetch: {
            jobs: requestHandlers => requestHandlers.jobsBySubsidiary
          },
          derived: ({ data, derived, route }) => deriveGenericDocumentInformation('subsidiary', { data, derived, route }),
          extractParams: { subsidiary: extract(language, slug) },
        },
      },

      jobArea: {
        data: {
          groq: ({ params: { language, slug } }) => ({
            doc: snippets.jobAreaDocument('jobAreaPage', snippets.projections.jobAreaPage, { language, slug }),
          }),
          fetch: {
            jobs: requestHandlers => requestHandlers.jobsByJobArea,
          },
          derived: ({ data, derived, route }) => deriveJobAreaPageInformation({ data, derived, route }),
          extractParams: { jobAreaPage: extract(language, jobAreaSlug) },
        },
      },

      jobs: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: snippets.singletonDocument('jobsOverview', snippets.projections.jobsOverview, { language }),
          }),
          fetch: {
            initialJobs: requestHandlers => requestHandlers.jobs,
            jobFilters: requestHandlers => requestHandlers.jobFilters,
          },
          derived: ({ data, derived, route }) => deriveGenericDocumentInformation('jobs-overview', { data, derived, route }),
          extractParams: { jobsOverview: extract(language) }
        },

        detail: {
          data: {
            fetch: {
              job: requestHandlers => requestHandlers.job,
              countries: requestHandlers => requestHandlers.countries,
            },
            derived: ({ data, derived, route }) => derivedJobInformation('job-detail', { data, derived, route }),
          },
        },
        apply: {
          data: {
            fetch: {
              job: requestHandlers => requestHandlers.job,
              countries: requestHandlers => requestHandlers.countries,
            },
            derived: ({ data, derived, route }) => derivedJobInformation('job-detail-form', { data, derived, route }),
          },
        },
        privateDetail: {
          data: {
            fetch: {
              job: requestHandlers => requestHandlers.privateJob,
              countries: requestHandlers => requestHandlers.countries,
            },
            derived: ({ data, derived, route }) => derivedJobInformation('job-detail', { data, derived, route }),
          },
        },
        internalDetail: {
          data: {
            fetch: {
              job: requestHandlers => requestHandlers.internalJob,
              countries: requestHandlers => requestHandlers.countries,
            },
            derived: ({ data, derived, route }) => deriveInternalJobInformation('job-detail', { data, derived, route }),
          },
        },
        internalApply: {
          data: {
            fetch: {
              job: requestHandlers => requestHandlers.internalJob,
              countries: requestHandlers => requestHandlers.countries,
            },
            derived: ({ data, derived, route }) => deriveInternalJobApplicationInformation('job-detail', { data, derived, route }),
          },
        },

        internalReferrer: {
          data: {
            fetch: {
              job: requestHandlers => requestHandlers.internalJob,
              countries: requestHandlers => requestHandlers.countries,
            },
            derived: ({ data, derived, route }) => deriveInternalReferrerInformation('job-detail', { data, derived, route }),
          },
        },

        privateInternalDetail: {
          data: {
            fetch: {
              job: requestHandlers => requestHandlers.privateInternalJob,
              countries: requestHandlers => requestHandlers.countries,
            },
            derived: ({ data, derived, route }) => deriveInternalJobInformation('job-detail', { data, derived, route }),
          },
        },
        privateInternalApply: {
          data: {
            fetch: {
              job: requestHandlers => requestHandlers.privateInternalJob,
              countries: requestHandlers => requestHandlers.countries,
            },
            derived: ({ data, derived, route }) => deriveInternalJobApplicationInformation('job-detail', { data, derived, route }),
          },
        },
      },
    }
  }
}

function deriveJobAreaPageInformation({ data, derived, route }) {
  return {
    ...deriveDocumentInformation({ data, derived }, {
      description: x => toPlainText(x.introduction ?? ''),
      dateCreated: x => utcDateTimeToFormattedDate(x._createdAt),
      dateUpdated: x => utcDateTimeToFormattedDate(x._updatedAt),
      wordCount: x => wordCount(x.content ?? []),
      translations: x => x?.translations?.map(translation => ({
        href: route({
          language: translation?.language,
          slug: data.doc?.jobAreaReference?.slug?.current
        }),
        language: translation?.language
      })),
      canonicalUrl: data.doc && route(extract(language, jobAreaSlug)(data.doc)),
      type: 'job-area-page',
      jobAreaValue: data.doc?.jobAreaReference?.ATSJobAreaID,
    }),
  }
}

function deriveInternalDocumentInformation(type, { data, derived, route }, fallbackRouteData) {
  const { isLoggedIn } = data.userInfo
  if (!isLoggedIn) return {
    ...fallbackRouteData,
    status: FORBIDDEN
  }

  return deriveGenericDocumentInformation(type, { data, derived, route })
}

function deriveGenericDocumentInformation(type, { data, derived, route }) {
  const translations = x => x?.translations?.map(translation => ({
    href: route({
      language: translation?.language,
      slug: translation?.slug?.current
    }),
    language: translation?.language
  }))

  return deriveDocumentInformation({ data, derived }, {
    description: x => toPlainText(x.introduction ?? ''),
    dateCreated: x => utcDateTimeToFormattedDate(x._createdAt),
    dateUpdated: x => utcDateTimeToFormattedDate(x._updatedAt),
    wordCount: x => wordCount(x.content ?? []),
    canonicalUrl: data.doc && route(extract(language, slug)(data.doc)),
    translations,
    type,
  })
}

function deriveDocumentInformation(
  {
    data,
    derived = { dataLayer: {} },
  },
  {
    title = x => x.title,
    description = x => undefined,
    shareImage = _ => data.settings?.shareImage,
    dateUpdated = x => undefined,
    dateCreated = _ => undefined,
    wordCount = _ => undefined,
    translations = _ => undefined,
    jobAreaValue = undefined,
    type = undefined,
    canonicalUrl = undefined,
  } = {}
) {
  const { doc } = data
  if (!doc) return

  const dateUpdatedValue = dateUpdated(doc)
  const dateCreatedValue = dateCreated(doc)
  const wordCountValue = wordCount(doc)

  return {
    doc: docWithSeo(doc, {
      title: title(doc),
      description: description(doc),
      social: {
        shareImage: shareImage(doc)
      },
      ...(canonicalUrl && { advanced: { canonicalUrl } }),
      translations: translations(doc)
    }),
    dataLayer: {
      ...derived.dataLayer,
      metadata: {
        ...derived.dataLayer?.metadata,
        content: {
          ...derived.dataLayer?.metadata?.content,
          title: title(doc),
          ...(dateUpdatedValue && { dateupdated: dateUpdatedValue }),
          ...(dateCreatedValue && { datecreated: dateCreatedValue }),
          ...(dateCreatedValue && { datepublished: dateCreatedValue }), //Check with OM if we need to include these
          ...(wordCountValue && { wordcount: wordCountValue }),
          ...(jobAreaValue && { jobarea: jobAreaValue }),
          language: data.doc.language,
          type,
        },
        template: {
          type,
        }
      }
    },
  }
}

function deriveInternalJobApplicationInformation(type, { data, derived, route }) {
  const { isLoggedIn, claims } = data.userInfo

  const { allowedToApplyToInternalVacancies } = claims?.permissions || {}
  if (!isLoggedIn || !allowedToApplyToInternalVacancies) return {
    job: {},
    status: FORBIDDEN
  }

  return derivedJobInformation(type, { data, derived, route })
}

function deriveInternalReferrerInformation(type, { data, derived, route }) {
  const { isLoggedIn, claims } = data.userInfo

  const { allowedToApplyToInternalVacancies } = claims?.permissions || {}
  if (!isLoggedIn || !allowedToApplyToInternalVacancies) return {
    job: {},
    status: FORBIDDEN
  }

  const { job } = data
  const { canBeReferred } = job || {}

  if (!canBeReferred) {
    return {
      status: NOT_FOUND,
    }
  }

  return derivedJobInformation(type, { data, derived, route })
}

function deriveInternalJobInformation(type, { data, derived, route }) {
  const { isLoggedIn } = data.userInfo

  if (!isLoggedIn) return {
    job: {},
    status: FORBIDDEN
  }

  return derivedJobInformation(type, { data, derived, route })
}

function derivedJobInformation(type, { data, derived, route }) {
  const { job } = data
  const { status, canonicalUrl, translations } = job

  if (status === NOT_FOUND) {
    return {
      status,
    }
  }

  const { jobAreaDetail } = job?.jobAreaInfo || {}

  return {
    doc: docWithSeo(job, {
      title: job.title,
      description: toPlainText(job.description || []),
      social: {
        shareImage: jobAreaDetail?.seo?.social?.shareImage
          || jobAreaDetail?.heroImage
          || data.settings?.shareImage
      },
      ...(canonicalUrl && { advanced: { canonicalUrl } }),
      translations: translations.map(job => ({
        href: route({
          language: job.language,
          jobTitle: makeSlug({ input: job.title, language: job.language }),
          jobId: job.id
        }),
        language: job.language
      }))
    }),
    dataLayer: {
      ...derived.dataLayer,
      metadata: {
        ...derived.dataLayer?.metadata,
        job: mapJobForDataLayer(job),
        content: {
          ...derived.dataLayer?.metadata?.content,
          id: job.id.toString(),
          title: job.title,
          datepublished: unixTimeStampToFormattedDate(job.publishedDate),
          dateupdated: unixTimeStampToFormattedDate(job.updatedDate),
          datecreated: unixTimeStampToFormattedDate(job.updatedDate),
          type,
        },
        template: {
          type,
        }
      }
    },
    structuredData: [
      {
        type: 'job-posting',
        data: {
          publishedDate: unixTimeStampToFormattedDate(job.publishedDate),
          validThrough: unixTimeStampToFormattedDate(job.closedDate),
          employmentType: job.employmentType?.label,
          description: toPlainText(job.description || []),
          city: job.location,
          title: job.title,
          id: job.id,
        },
      },
    ],
  }
}

function jobAreaSlug(doc, { client = null } = {}) {
  if (doc?.jobAreaReference?.slug?.current)
    return { slug: doc.jobAreaReference.slug.current }

  return client.fetch(groq`*[_type=='jobArea' && _id==$id][0]`, { id: doc.jobAreaReference._ref }).then(x => ({ slug: x.slug.current }))
}

function withNormalizedContent(data) {
  if (data.doc?.content)
    data.doc.content = data.normalizedContent

  return data
}

function isExcludedFromTracking({ excludedIps, query, headers, ip }) {
  const hasExcludeFromTrackingCookie = Boolean(cookie.parse(headers?.cookie || '').exclude_gtm)
  const excludeFromTracking = Boolean(
    hasExcludeFromTrackingCookie
    || query?.exclude_gtm
    || excludedIps?.some(x => x === ip)
  )
  return { excludeFromTracking, hasExcludeFromTrackingCookie }
}
